import React, { useEffect, useRef, useState } from 'react';
import API from 'libs/api-lib';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';
import datelib from 'libs/date-lib';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'i18n';

import CMP_CREDIT_ACTIVITY_LIST from 'components/cmp_credit_activity_list/cmp_credit_activity_list';


export default function MDL_CREDIT_ACTIVITY_MANUAL_ENTRY({ is_open, holder_name, onClose, onChange, credential_id, individual_credential_id }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_credit_activity_entries, set_credit_activity_entries  ] = useState([]);
    const [ var_credit_total, set_credit_total] = useState(0);
    const [ var_credit_error, set_credit_error ] = useState(null);
    const [ var_api_error, set_api_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);

    const modal_header_ref = useRef();

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open) {
            // reset
            set_modal(document.querySelector('#mdl_manual_entry'));
            set_processing(false);
            set_api_error(null);
            set_credit_error(null);
            set_credit_activity_entries([]);
            set_credit_total(0);
            populate_credit_template();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open]);

    useEffect(() => {
        const initial_value = 0;
        let total_credits = var_credit_activity_entries.reduce((accumulator, current_value) => accumulator + Number(current_value?.credits ?? 0), initial_value);
        set_credit_total(total_credits);
    }, [var_credit_activity_entries])

    useEffect(()=> {
        var_credit_error && modal_header_ref?.current.scrollIntoView();
    },[var_credit_error])

    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_credit_template() {
        set_processing(true);
        try {
            let results = await API_get_credit_template();
            set_credit_activity_entries(results);
        } catch (e) {
            console.log(e);
            set_api_error(t('There was a problem fetching data.  Please try again later'));
        }
        set_processing(false);
    }

    async function save_details() {
        if (var_processing) return;
        set_processing(true);
        try {
            await API_post_credit_activity_entries();
            onChange();
        } catch (e) {
            console.log(e);
            set_api_error(t('An error has occurred'));
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credit_template() {
        return API.get('credentials', `/get-credential-credit-list/${credential_id}`);
    }

    function API_post_credit_activity_entries() {
        return API.post('credentials', '/post-credential-credit-activities/',
            {
                queryStringParameters: { tz: datelib.timezone },
                body: { individual_credential_id, activities: var_credit_activity_entries}
            });
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_save() {
        if (!var_credit_total > 0) {
            set_credit_error(t('No tasks have assigned credit values. Please enter credit values to save.'));
            return;
        } 

        let credit_activity_entries = _.cloneDeep(var_credit_activity_entries);
        for (let credit_activity of credit_activity_entries) {
            credit_activity.activity_date = credit_activity.activity_date ? datelib.localdate_to_utc_midnight_epoch(credit_activity.activity_date) : null;
        }

        save_details();
    }

    function onChange_activity(activity) {
        if (!activity) return;

        set_credit_error(null);
        
        const updated_activities = var_credit_activity_entries.map(item => item.id === activity.id ? activity : item);
        set_credit_activity_entries(updated_activities);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_manual_entry'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            aria-modal='true'
            role='dialog'
            aria-labelledby='hdr_manual_entry'
        >

            <div className='modal__header' ref={modal_header_ref}>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_manual_entry'>{holder_name}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_manual_entry' aria-labelledby='hdr_manual_entry' tabIndex='0'>

                {var_api_error &&
                    <Message error header={var_api_error} />
                }

                {var_credit_error &&
                    <Message
                        error
                        icon={<Icon name='error' className='icon' />}
                        header={var_credit_error}
                    />
                }
                <CMP_CREDIT_ACTIVITY_LIST activities={var_credit_activity_entries} onChange_activity={onChange_activity}/>
                <div>{var_credit_total}</div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_save}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );

}
